import { useState } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const Footer = () => {
  // const [pageId, setPageId] = useState('');
  // const [appId, setAppID] = useState('');

  // setTimeout(() => {
  //   let fbChatPageAppID = localStorage.getItem('fbChatPageAppID');
  //   setAppID(fbChatPageAppID);

  //   let fbChatPageID = localStorage.getItem('fbChatPageID');
  //   setPageId(fbChatPageID);
  // }, 100);

  return (
    <div>
      {/* <MessengerCustomerChat pageId={pageId} appId={appId} /> */}

      <div
        className="sub-footer py-3"
        style={{ background: '#000837' }}
      >
        <div className="container">
          <div
            className="footer text-center"
            style={{ color: '#FFFF' }}
          >
            © {new Date().getFullYear()} Subah - All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
