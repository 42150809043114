import React, { useState } from "react";
import { Button, Form, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

const initialValue = {
  email: "",
  first_name: "",
  last_name: "",
  contact_number: "",
  message: "",
};

export default function Career() {
  const [isOpen, setIsOpen] = useState(false);
  const [err, seterr] = useState("");
  const [successMsg, setsuccessMsg] = useState("");
  const [validate_data, setvalidate_data] = useState([]);
  const [inputsData, setinputsData] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setvalidate_data([]);
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      seterr("Please Select All requied Fields");
      let obj = Object.keys(inputsData);
      for (let index = 0; index < obj.length; index++) {
        if (obj[index] != "promo_code") {
          if (!inputsData[obj[index]]) {
            validate_data.push(obj[index]);
          }
        }
      }
    } else {
      setLoading(true);
    
      const url = `${process.env.REACT_APP_DEV_API_URL}/support-message`
    
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...inputsData,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success === true) {
            setLoading(false);
            toast.success(data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
            setinputsData([]);

            form.reset();
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        });
    }
  };

  const inputV = async (fields, value) => {
    setinputsData({ ...inputsData, [fields]: value });
  };

  return (
    <div className="container" style={{ height: isOpen ? "1020px" : "800px" }}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="row">
        <div className="column support_page">
          <Form
            style={{
              margin: "auto",
              maxWidth: "600px",
              width: "100%",
              padding: "80px 0",
            }}
            onSubmit={handleSubmit}
          >
            <h3 style={{ color: "green" }}>{successMsg}</h3>
            <h3>Support</h3>
            <br />

            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={inputsData.email}
                  required
                  onChange={(e) => inputV(e.target.name, e.target.value)}
                />
              </Form.Group>

              <br />
              <Form.Group controlId="formGridAddress1">
                <Form.Control
                  placeholder="First Name"
                  name="first_name"
                  value={inputsData.first_name}
                  required
                  onChange={(e) => inputV(e.target.name, e.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="formGridAddress2">
                <Form.Control
                  placeholder="Last Name"
                  name="last_name"
                  value={inputsData.last_name}
                  required
                  onChange={(e) => inputV(e.target.name, e.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="formGridAddress3">
                <Form.Control
                  placeholder="Phone Number"
                  name="contact_number"
                  value={inputsData.contact_number}
                  required
                  onChange={(e) => inputV(e.target.name, e.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="formGridAddress4">
                <textarea
                  placeholder="Your Message ..."
                  name="message"
                  className="form-control"
                  rows="4"
                  style={{ height: "100%" }}
                  required
                  onChange={(e) => inputV(e.target.name, e.target.value)}
                >
                  {inputsData.message}
                </textarea>
              </Form.Group>
              <br />
            </Form.Row>

            <div className="">
              <Button
                variant="primary"
                type="submit"
                className=""
                style={{
                  width: "100px",
                  height: "40px",
                }}
              >
                {loading ? (
                  <div
                    class="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                      margin: "auto",
                    }}
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <p>Submit</p>
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
