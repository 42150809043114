/* eslint-disable */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useCookies } from "react-cookie";

const Signup = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["_jwt"]);
  const [hideOrNot, setHideOrNot] = useState(true);
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [regInfo, setRegInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    ip_address: "",
    user_type: "user",
    login_medium: "email",
    verify_by: "email",
  });

  const [ip, setIP] = useState("");

  //creating function to load ip address from the API
  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   setIP(res.data.IPv4);
  // };

  // Updated Code

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setRegInfo({ ...regInfo, ip_address: ip });
  }, [ip]);
  const formSubmitHandler = (eve) => {
    eve.preventDefault();
    setInProgress(true);

    if (regInfo.password !== regInfo.confirm_password) {
      setInProgress(false);
      return toast.error("Password didn't match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
    if (
      regInfo.first_name === "" ||
      regInfo.last_name === "" ||
      regInfo.email === "" ||
      regInfo.phone === "" ||
      regInfo.password === "" ||
      regInfo.confirm_password === ""
    ) {
      setInProgress(false);
      return toast.error("Please fill all the fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    } else {
      fetch(`${process.env.REACT_APP_DEV_API_URL}/user/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(regInfo),
      })
        .then((response) => response.json())
        .then((data) => {
          setInProgress(false);
          if (data.success === false) {
            return toast.error(data.errors[0], {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
          }
          if (data.success === true) {
            localStorage.setItem("_info", JSON.stringify(regInfo));
            setCookie("_jwt", data.authorization.token, { path: "/" });
            setCookie("token", data.authorization.token, { path: "/" });

            navigate("/verification");
          }
        })
        .catch((err) => {
          setInProgress(false);
          console.log(err);
        });
      // axios({
      //   method: "post",
      //   url: "https://subah.bdtask-demo.com/b2c/api/user/register",
      //   data: regInfo,
      // })
      //   .then(({ data }) => {
      //     setInProgress(false);
      //     return;
      //     if (data.success === false) {
      //       return toast.error(data.errors[0], {
      //         position: "top-right",
      //         autoClose: 3000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: false,
      //         draggable: false,
      //         progress: undefined,
      //       });
      //     }
      //     if (data.success === true) {
      //       localStorage.setItem("_info", JSON.stringify(regInfo));
      //       setCookie("_jwt", data.authorization.token, { path: "/" });
      //       navigate("/verification");
      //     }
      //   })
      //   .catch((err) => {
      //     setInProgress(false);
      //     console.log(err);
      //   });
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
      />
      <ToastContainer />
      <div className="auth-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-7 col-xl-5">
              <div className="form-card">
                <div className="form-card_body">
                  <div className="authentic-logo mb-3">
                    <img src="assets/img/logo-desktop.png" alt="" />
                  </div>
                  <div className="mb-5">
                    <h5 className="mt-0 font-weight-semi-bold">Sign Up</h5>
                    <p>Create an account to easily use sky trip.com services</p>
                  </div>{" "}
                  <form onSubmit={formSubmitHandler}>
                    <div className="custom-input-group mb-3">
                      <div className="apend-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="gray"
                        >
                          <path d="M12 12c2.7 0 4.8-2.1 4.8-4.8S14.7 2.4 12 2.4 7.2 4.5 7.2 7.2 9.3 12 12 12zm0 1.2c-3.2 0-9.6 1.6-9.6 4.8v2.4h19.2v-2.4c0-3.2-6.4-4.8-9.6-4.8z" />
                        </svg>
                      </div>
                      <div className="form-group">
                        <label>First Name </label>
                        <input
                          type="text"
                          placeholder="Your name "
                          className="form-control"
                          value={regInfo.first_name}
                          onChange={(e) => {
                            setRegInfo({
                              ...regInfo,
                              first_name: e.target.value,
                            });
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="custom-input-group mb-3">
                      <div className="apend-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="gray"
                        >
                          <path d="M12 12c2.7 0 4.8-2.1 4.8-4.8S14.7 2.4 12 2.4 7.2 4.5 7.2 7.2 9.3 12 12 12zm0 1.2c-3.2 0-9.6 1.6-9.6 4.8v2.4h19.2v-2.4c0-3.2-6.4-4.8-9.6-4.8z" />
                        </svg>
                      </div>
                      <div className="form-group">
                        <label>Last Name </label>
                        <input
                          type="text"
                          placeholder="Your name "
                          className="form-control"
                          value={regInfo.last_name}
                          onChange={(e) => {
                            setRegInfo({
                              ...regInfo,
                              last_name: e.target.value,
                            });
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="custom-input-group mb-3">
                      <div className="apend-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24.44"
                          height="16.293"
                          viewBox="0 0 24.44 16.293"
                        >
                          <path
                            id="Path_122"
                            data-name="Path 122"
                            d="M23.187,85.333H1.253A1.257,1.257,0,0,0,0,86.586v13.787a1.257,1.257,0,0,0,1.253,1.253H23.187a1.257,1.257,0,0,0,1.253-1.253V86.586A1.257,1.257,0,0,0,23.187,85.333Zm-.47.94-9.775,7.332a1.31,1.31,0,0,1-1.442,0L1.724,86.273Zm-5.222,7.816,5.327,6.58c.005.007.012.012.018.018H1.6c.006-.006.012-.011.018-.018l5.327-6.58a.47.47,0,0,0-.731-.592L.94,100.013V86.86l10,7.5a2.243,2.243,0,0,0,2.569,0l10-7.5v13.152L18.225,93.5a.47.47,0,0,0-.731.592Z"
                            transform="translate(0 -85.333)"
                            fill="gray"
                          />
                        </svg>
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          placeholder="mail@email.com"
                          className="form-control"
                          value={regInfo.email}
                          onChange={(e) => {
                            setRegInfo({ ...regInfo, email: e.target.value });
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="custom-input-group mb-3">
                      <div className="apend-wrap">
                        <svg
                          id="Component_1_1"
                          data-name="Component 1 – 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.098"
                          height="19.012"
                          viewBox="0 0 19.098 19.012"
                        >
                          <path
                            id="Path_131"
                            data-name="Path 131"
                            d="M18.548,14.816l-2.76-2.761a1.967,1.967,0,0,0-1.4-.572,2.063,2.063,0,0,0-1.467.612l-1.074,1.074a13.084,13.084,0,0,1-3.1-2.254A13.092,13.092,0,0,1,6.5,7.817L7.571,6.742a2.03,2.03,0,0,0,.037-2.865l-2.76-2.76A1.958,1.958,0,0,0,3.45.542a2.065,2.065,0,0,0-1.442.586A5.91,5.91,0,0,0,.139,4.534C-.482,7.565.944,10.481,5.061,14.6c4.6,4.6,8.525,4.955,9.619,4.955a3.635,3.635,0,0,0,.4-.018,4.555,4.555,0,0,0,3.473-1.9A2.09,2.09,0,0,0,18.548,14.816Zm-.916,2.077a3.372,3.372,0,0,1-2.689,1.466,2.64,2.64,0,0,1-.264.01c-.979,0-4.5-.332-8.781-4.608-3.8-3.8-5.129-6.4-4.6-8.99A4.661,4.661,0,0,1,2.753,2.05l.035-.026.031-.031a.889.889,0,0,1,.631-.266.782.782,0,0,1,.559.227l2.76,2.76A.844.844,0,0,1,6.733,5.9L5.517,7.12l-.02.021a.97.97,0,0,0-.105,1.128,14.4,14.4,0,0,0,2.522,3.483,14.379,14.379,0,0,0,3.474,2.516,1.071,1.071,0,0,0,.513.136.948.948,0,0,0,.72-.331l1.141-1.141a.886.886,0,0,1,.629-.264.789.789,0,0,1,.561.226l2.758,2.76A.932.932,0,0,1,17.632,16.893Z"
                            transform="translate(0 -0.542)"
                            fill="gray"
                          />
                          <path
                            id="Path_132"
                            data-name="Path 132"
                            d="M140.329,23.523a5.621,5.621,0,0,1,4.629,4.656.593.593,0,0,0,.588.529.616.616,0,0,0,.065,0,.592.592,0,0,0,.525-.653,6.8,6.8,0,0,0-5.672-5.706.593.593,0,0,0-.135,1.177Z"
                            transform="translate(-128.758 -20.619)"
                            fill="gray"
                          />
                          <path
                            id="Path_133"
                            data-name="Path 133"
                            d="M133.321,54.939a3.55,3.55,0,0,1,2.785,2.8.593.593,0,0,0,.588.529.614.614,0,0,0,.065,0,.593.593,0,0,0,.525-.653,4.718,4.718,0,0,0-3.828-3.85.593.593,0,1,0-.135,1.177Z"
                            transform="translate(-122.304 -49.554)"
                            fill="gray"
                          />
                        </svg>
                      </div>
                      <div className="form-group">
                        <label>Mobile</label>
                        <input
                          type="number"
                          placeholder="+880 Ex : 1xxxxxxxxxxxxx"
                          className="form-control"
                          value={regInfo.phone}
                          onChange={(e) => {
                            setRegInfo({ ...regInfo, phone: e.target.value });
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="custom-input-group mb-3">
                      <div className="apend-wrap">
                        <svg
                          id="Component_2_1"
                          data-name="Component 2 – 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.115"
                          height="22.071"
                          viewBox="0 0 16.115 22.071"
                        >
                          <path
                            id="Path_123"
                            data-name="Path 123"
                            d="M172.561,296.171a.647.647,0,0,0,.647-.647v-2.678a.647.647,0,1,0-1.293,0v2.678A.647.647,0,0,0,172.561,296.171Zm0,0"
                            transform="translate(-164.503 -279.603)"
                            fill="gray"
                          />
                          <path
                            id="Path_124"
                            data-name="Path 124"
                            d="M15.468,7.092h-3.06V3.943A4.166,4.166,0,0,0,8.057,0a4.166,4.166,0,0,0-4.35,3.943v3.15H.647A.647.647,0,0,0,0,7.739v8.269a6.069,6.069,0,0,0,6.062,6.062h3.99a6.069,6.069,0,0,0,6.062-6.062V7.739A.647.647,0,0,0,15.468,7.092ZM5,3.943A2.878,2.878,0,0,1,8.057,1.293a2.879,2.879,0,0,1,3.057,2.649v3.15H5Zm9.821,12.066a4.775,4.775,0,0,1-4.769,4.769H6.062a4.775,4.775,0,0,1-4.769-4.769V8.386H14.821Zm0,0"
                            fill="gray"
                          />
                        </svg>
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type={hideOrNot ? "password" : "text"}
                          placeholder="******************"
                          className="form-control password"
                          value={regInfo.password}
                          onChange={(e) => {
                            setRegInfo({
                              ...regInfo,
                              password: e.target.value,
                            });
                          }}
                          required
                        />
                        <i
                          className="fa fa-eye-slash"
                          onClick={() => {
                            setHideOrNot(!hideOrNot);
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="custom-input-group mb-3">
                      <div className="apend-wrap">
                        <svg
                          id="Component_2_1"
                          data-name="Component 2 – 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.115"
                          height="22.071"
                          viewBox="0 0 16.115 22.071"
                        >
                          <path
                            id="Path_123"
                            data-name="Path 123"
                            d="M172.561,296.171a.647.647,0,0,0,.647-.647v-2.678a.647.647,0,1,0-1.293,0v2.678A.647.647,0,0,0,172.561,296.171Zm0,0"
                            transform="translate(-164.503 -279.603)"
                            fill="gray"
                          />
                          <path
                            id="Path_124"
                            data-name="Path 124"
                            d="M15.468,7.092h-3.06V3.943A4.166,4.166,0,0,0,8.057,0a4.166,4.166,0,0,0-4.35,3.943v3.15H.647A.647.647,0,0,0,0,7.739v8.269a6.069,6.069,0,0,0,6.062,6.062h3.99a6.069,6.069,0,0,0,6.062-6.062V7.739A.647.647,0,0,0,15.468,7.092ZM5,3.943A2.878,2.878,0,0,1,8.057,1.293a2.879,2.879,0,0,1,3.057,2.649v3.15H5Zm9.821,12.066a4.775,4.775,0,0,1-4.769,4.769H6.062a4.775,4.775,0,0,1-4.769-4.769V8.386H14.821Zm0,0"
                            fill="gray"
                          />
                        </svg>
                      </div>
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          type={hideOrNot ? "password" : "text"}
                          placeholder="******************"
                          className="form-control password"
                          value={regInfo.confirm_password}
                          onChange={(e) => {
                            setRegInfo({
                              ...regInfo,
                              confirm_password: e.target.value,
                            });
                          }}
                          required
                        />
                        <i
                          className="fa fa-eye-slash"
                          onClick={() => {
                            setHideOrNot(!hideOrNot);
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        By creating an account you agree to our
                        <Link to="/term-condition">Terms & Condition</Link>
                      </label>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-block btn-authentication w-100"
                      disabled={inProgress}
                    >
                      Sign Up
                    </button>
                    <div className="bottom-text text-center mt-3 font-weight-500">
                      Already have an Account?
                      <Link to="/login" className="fw-medium">
                        Sign In
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
