import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChnagePassword = () => {
  const [hideOrNot, setHideOrNot] = useState(true);
  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("_user_id_") === null) {
      window.location.assign("/");
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setIsDisable(true);
    fetch(`${process.env.REACT_APP_DEV_API_URL}/user/change-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        old_password: oldPwd,
        new_password: newPwd,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success === true) {
          setLoading(false);
          toast.success("Password Changed Successful", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/user-profile");
          }, 3000);
        } else {
          setIsDisable(false);
          setLoading(false);
          toast.error("Please insert your correct old password", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container" style={{ minHeight: "60vh" }}>
      <Link className="btn btn-warning mt-2" onClick={() => navigate(-1)}>
        Go Back
      </Link>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
        />
        <div className="auth-wrap">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-7 col-xl-5">
                <div className="form-card">
                  <div className="form-card_body">
                    <form onSubmit={submitHandler}>
                      <div className="custom-input-group mb-3">
                        <div className="apend-wrap">
                          <svg
                            id="Component_2_1"
                            data-name="Component 2 – 1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.115"
                            height="22.071"
                            viewBox="0 0 16.115 22.071"
                          >
                            <path
                              id="Path_123"
                              data-name="Path 123"
                              d="M172.561,296.171a.647.647,0,0,0,.647-.647v-2.678a.647.647,0,1,0-1.293,0v2.678A.647.647,0,0,0,172.561,296.171Zm0,0"
                              transform="translate(-164.503 -279.603)"
                              fill="gray"
                            />
                            <path
                              id="Path_124"
                              data-name="Path 124"
                              d="M15.468,7.092h-3.06V3.943A4.166,4.166,0,0,0,8.057,0a4.166,4.166,0,0,0-4.35,3.943v3.15H.647A.647.647,0,0,0,0,7.739v8.269a6.069,6.069,0,0,0,6.062,6.062h3.99a6.069,6.069,0,0,0,6.062-6.062V7.739A.647.647,0,0,0,15.468,7.092ZM5,3.943A2.878,2.878,0,0,1,8.057,1.293a2.879,2.879,0,0,1,3.057,2.649v3.15H5Zm9.821,12.066a4.775,4.775,0,0,1-4.769,4.769H6.062a4.775,4.775,0,0,1-4.769-4.769V8.386H14.821Zm0,0"
                              fill="gray"
                            />
                          </svg>
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Old Password</label>
                          <input
                            type="password"
                            placeholder="******************"
                            className="form-control"
                            name="old-password"
                            value={oldPwd}
                            onChange={(e) => setOldPwd(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="custom-input-group mb-3">
                        <div className="apend-wrap">
                          <svg
                            id="Component_2_1"
                            data-name="Component 2 – 1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.115"
                            height="22.071"
                            viewBox="0 0 16.115 22.071"
                          >
                            <path
                              id="Path_123"
                              data-name="Path 123"
                              d="M172.561,296.171a.647.647,0,0,0,.647-.647v-2.678a.647.647,0,1,0-1.293,0v2.678A.647.647,0,0,0,172.561,296.171Zm0,0"
                              transform="translate(-164.503 -279.603)"
                              fill="gray"
                            />
                            <path
                              id="Path_124"
                              data-name="Path 124"
                              d="M15.468,7.092h-3.06V3.943A4.166,4.166,0,0,0,8.057,0a4.166,4.166,0,0,0-4.35,3.943v3.15H.647A.647.647,0,0,0,0,7.739v8.269a6.069,6.069,0,0,0,6.062,6.062h3.99a6.069,6.069,0,0,0,6.062-6.062V7.739A.647.647,0,0,0,15.468,7.092ZM5,3.943A2.878,2.878,0,0,1,8.057,1.293a2.879,2.879,0,0,1,3.057,2.649v3.15H5Zm9.821,12.066a4.775,4.775,0,0,1-4.769,4.769H6.062a4.775,4.775,0,0,1-4.769-4.769V8.386H14.821Zm0,0"
                              fill="gray"
                            />
                          </svg>
                        </div>
                        <div className="form-group">
                          <label>New Password</label>
                          <input
                            type={hideOrNot ? "password" : "text"}
                            name="password"
                            placeholder="******************"
                            className="form-control password"
                            value={newPwd}
                            onChange={(e) => setNewPwd(e.target.value)}
                            required
                          />
                          <i
                            className="fa fa-eye-slash"
                            onClick={() => {
                              setHideOrNot(!hideOrNot);
                            }}
                          ></i>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-block btn-authentication w-100"
                        // disabled={isDisable}
                        disabled
                      >
                        Change Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChnagePassword;
