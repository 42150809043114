import { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import loadedGif from "./Loader.gif";

import "./TicketPage.css";

const TicketPage = () => {
  const [buffer, setBuffer] = useState(true);

  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState("");
  const [slack, setSlack] = useState("");

  const [TicketInformation, setTicketInformation] = useState({});

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get("status");
    const slack = queryParams.get("slack");

    if (status === null || slack === null || status === "" || slack === "") {
      window.location.assign("/");
    } else {
      setSlack(slack);
      setBuffer(false);
      setStatus(status);
    }
  }, []);

  const timeCalc = (time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    if (hours === 0) return minutes + "min";
    return hours + "hr " + minutes + "min";
  };
  useEffect(() => {
    if (status && slack) {
      fetch(`${process.env.REACT_APP_DEV_API_URL}/gds/reservation-info`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          tran_id: slack,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success === true) {
            setLoading(false);
            setTicketInformation(res.data);
          } else {
            setHasError(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [status, slack]);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <>
      {!buffer && (
        <>
          {hasError ? (
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ height: "80vh" }}
            >
              <span
                style={{
                  textAlign: "center",
                }}
              >
                Sorry ! <br /> Your PNR is not found. duo to some technical
                issue.
                <br />
                Please try again later.
              </span>
            </div>
          ) : (
            <>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center flex-column"
                  style={{ height: "80vh" }}
                >
                  <loadedGif />
                  <span>Preparing...</span>
                </div>
              ) : (
                <>
                  {" "}
                  <div>
                    <div
                      className="body0container"
                      style={{
                        fontSize: "15px",
                        backgroundColor: "#f7f7f7",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        lineHeight: "1.5",
                        color: "#212529",
                        textAlign: "left",
                      }}
                    >
                      <div
                        className="main0container"
                        style={{
                          width: "100%",
                          paddingRight: "17px",
                          paddingLeft: "15px",
                          marginRight: "auto",
                          maxWidth: "950px",
                          marginLeft: "auto",
                        }}
                      >
                        <div style={{ justifyContent: "center" }}>
                          <div
                            style={{
                              paddingRight: "15px",
                              paddingLeft: "15px",
                            }}
                          >
                            <div style={{ textAlign: "right" }}>
                              <button
                                style={{
                                  color: "#fff",
                                  backgroundColor: "#17c653",
                                  textDecoration: "none",
                                  borderColor: "#17c653",
                                  marginBottom: "1rem",
                                  display: "inline-block",
                                  border: "1px solid transparent",
                                  padding: "0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  boxShadow:
                                    "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                                  fontSize: "1rem",
                                }}
                                onClick={handlePrint}
                              >
                                <i class="flaticon2-printer"></i>
                                Print PDF
                              </button>
                            </div>
                            <div className="wrapper_">
                              <div className="invoice-wrap" ref={printRef}>
                                <div style={{ marginBottom: "20px" }}>
                                  <div>
                                    <img
                                      src="https://subah.bdtask-demo.com/b2c/storage/setting/logo.png?v=94"
                                      class="invoice-logo"
                                      alt="logo"
                                    />
                                  </div>
                                </div>
                                <h4
                                  style={{
                                    paddingBottom: "1px",
                                    paddingTop: "0.5 rem",
                                    marginBottom: "6px",
                                    borderBottom: "2px solid #dee2e6",
                                    borderColor: "#333",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    lineHeight: "1.2",
                                    marginTop: "0",
                                  }}
                                >
                                  <span
                                    style={{
                                      marginRight: "1rem",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {TicketInformation.journey_start}

                                    <small
                                      style={{
                                        marginRight: "2px",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      <i
                                        class="fa fa-caret-right"
                                        style={{ fontSize: "20px" }}
                                      ></i>
                                    </small>
                                    {TicketInformation.journey_end}
                                  </span>
                                  TRIP TO
                                  <span
                                    style={{
                                      marginLeft: "12px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {TicketInformation.last_airport}
                                  </span>
                                  {","}
                                  <span
                                    style={{
                                      marginLeft: "12px",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {
                                      TicketInformation.flights_info[0]
                                        .airline_details
                                    }
                                  </span>
                                </h4>
                                <div
                                  style={{
                                    marginRight: "-15px",
                                    marginLeft: "-15px",
                                    marginBottom: "15px",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      paddingLeft: "15px",
                                      maxWidth: "50%",
                                      flex: "0 0 50%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        color: "#3c3c3c",
                                        fontWeight: "400",
                                      }}
                                    >
                                      PREPARED FOR
                                    </div>
                                    {TicketInformation.pxn_list !== undefined &&
                                      TicketInformation.pxn_list.map((pxn) => (
                                        <div
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "19px",
                                            color: "#3a3a3a",
                                            lineHeight: "22px",
                                          }}
                                        >
                                          {pxn.pxn_name}
                                        </div>
                                      ))}
                                  </div>

                                  <div
                                    style={{
                                      fontWeight: "500",
                                      textTransform: "uppercase",
                                      fontSize: "13px",
                                      color: "#464646",
                                    }}
                                  >
                                    Travel Consultant — Subah
                                  </div>
                                </div>
                                <div
                                  style={{
                                    marginRight: "0",
                                    marginLeft: "0",
                                    paddingBottom: "0",
                                    marginBottom: "0",
                                    borderBottom: "2px solid #dee2e6",
                                    borderColor: "#333",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      paddingRight: "0",
                                      paddingLeft: "0",
                                      flex: "0 0 66.666667%",
                                      maxWidth: "66.666667%",
                                    }}
                                  >
                                    <div style={{ lineHeight: "15px" }}>
                                      RESERVATION CODE :{" "}
                                      {TicketInformation?.reservation_code}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      paddingRight: "0",
                                      paddingLeft: "0",
                                      flex: "0 0 66.66667%",
                                    }}
                                  ></div>
                                </div>

                                {TicketInformation?.flights_info &&
                                  TicketInformation.flights_info.map((inf) => (
                                    <>
                                      <div
                                        style={{
                                          marginBottom: "1rem",
                                          marginTop: "1rem",
                                          display: "flex",
                                          alignItems: "center",
                                          marginRight: "-15px",
                                          marginLeft: "-15px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "0 0 50%",
                                            paddingLeft: "15px",
                                            maxWidth: "45%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "left",
                                            }}
                                          >
                                            <i
                                              className="fa fa-plane fa-6"
                                              style={{
                                                fontSize: "40px",
                                                color: "#3a3a3a",
                                              }}
                                              aria-hidden="true"
                                            ></i>
                                            <span
                                              style={{
                                                marginLeft: "0.5rem",
                                                textTransform: "uppercase",
                                                paddingTop: "12px",
                                              }}
                                            >
                                              DEPARTURE: {inf.departure_date}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            paddingLeft: "5px",
                                            paddingRight: "15px",
                                            flex: "0 0 50%",
                                            maxWidth: "55%",
                                            flexDirection: "flex-end",
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontWeight: "200",
                                              fontSize: "small",
                                            }}
                                          >
                                            Please verify flight times prior to
                                            departure
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          marginBottom: "0.5rem",
                                          marginRight: "0",
                                          display: "flex",
                                          marginLeft: "0",
                                        }}
                                      >
                                        <div
                                          style={{
                                            maxWidth: "25%",
                                            flex: "0 0 25%",
                                            backgroundColor: "#e7e7e9",
                                            padding: "10px",
                                            borderBottomLeftRadius: "30px",
                                            paddingLeft: "16px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontWeight: "500",
                                              lineHeight: "15px",
                                            }}
                                          >
                                            {inf.airline_details}
                                          </div>
                                          <div
                                            style={{
                                              fontWeight: "700",
                                              marginBottom: "3px",
                                            }}
                                          >
                                            {inf.airline_code}{" "}
                                            {inf.flight_number}
                                          </div>
                                          <div
                                            style={{
                                              fontSize: "12px",
                                              fontWeight: "400px",
                                              paddingTop: "3px",
                                              lineHeight: "11px",
                                            }}
                                          >
                                            Duration:
                                          </div>
                                          <div
                                            style={{
                                              fontWeight: "400",
                                              marginBottom: "4px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {timeCalc(inf.duration_minutes)}
                                          </div>
                                          <div
                                            style={{
                                              fontSize: "12px",
                                              lineHeight: "10px",
                                              paddingTop: "6px",
                                            }}
                                          >
                                            Cabin:
                                          </div>
                                          <div
                                            style={{
                                              fontWeight: "400",
                                              fontSize: "12px",
                                              textTransform: "lowercase",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            {inf.cabin_class}
                                          </div>
                                          {/* <div style={{ fontSize: "12px" }}>
                                            Status:
                                          </div>
                                          <div
                                            style={{
                                              fontWeight: "300",
                                              fontSize: "12px",
                                              lineHeight: "11px",
                                            }}
                                          >
                                            Confirmed
                                          </div> */}
                                        </div>

                                        <div
                                          style={{
                                            overflow: "hidden",
                                            textAlign: "center",
                                            paddingRight: "0",
                                            paddingLeft: "0",
                                            border: "1px solid #dee2e6",
                                            borderColor: "#707070",
                                            flex: "0 0 50%",
                                            maxWidth: "50%",
                                          }}
                                        >
                                          <div
                                            style={{
                                              paddingLeft: "0.5rem",
                                              paddingRight: ".5rem",
                                              paddingBottom: ".5rem",
                                              alignItems: "center",

                                              marginRight: "0",
                                              marginLeft: "0",
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                              style={{
                                                paddingRight: "0",
                                                textAlign: "left",
                                                paddingLeft: "0px",
                                                paddingTop: "9px",
                                                flexBasis: 0,
                                                flexGrow: "1",
                                                minWidth: 0,
                                                maxWidth: "100%",
                                              }}
                                            >
                                              <h4
                                                style={{
                                                  lineHeight: "1",
                                                  fontSize: "19px",
                                                  fontWeight: "500",
                                                  color: "#5d5d5d",
                                                  marginBottom: "0",
                                                  marginTop: "0",
                                                }}
                                              >
                                                {inf.from_airport}
                                              </h4>
                                              <div
                                                style={{
                                                  textTransform: "uppercase",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {inf.from_location}
                                              </div>
                                            </div>
                                            <i
                                              className="fas fa-caret-right"
                                              style={{ marginLeft: "10px" }}
                                            ></i>
                                            <div
                                              style={{
                                                paddingRight: "0",
                                                paddingLeft: "0",
                                                flexBasis: "0",
                                                paddingTop: "9px",
                                                flexGrow: "1",
                                                minWidth: "0",
                                                maxWidth: "100%",
                                                textAlign: "center",
                                              }}
                                            >
                                              <h4
                                                style={{
                                                  lineHeight: "1",
                                                  fontSize: "19px",
                                                  fontWeight: "500",
                                                  color: "#5d5d5d",
                                                  marginBottom: "0",
                                                  marginTop: "0",
                                                }}
                                              >
                                                {inf.to_airport}
                                              </h4>
                                              <div
                                                style={{
                                                  textTransform: "uppercase",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {inf.to_location}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              height: "100%",
                                              textAlign: "center",
                                              justifyContent: "space-between",
                                              borderTop: "1px solid #dee2e6",
                                              borderColor: "#707070",
                                              marginRight: 0,
                                              marginLeft: 0,
                                              display: "flex",
                                            }}
                                          >
                                            <div
                                              style={{
                                                paddingLeft: "9px",
                                                paddingRight: "1rem",
                                                paddingTop: ".5rem",
                                                borderRight:
                                                  "1px solid #dee2e6",
                                                borderColor: "#707070",
                                                flexBasis: "0",
                                                flexGrow: "1",
                                                minWidth: "0",
                                                maxWidth: "100%",
                                                textAlign: "left",
                                                lineHeight: "18px",
                                              }}
                                            >
                                              Departing At : <br />
                                              <span
                                                style={{ fontSize: "12px" }}
                                              >
                                                {inf.departure_date}
                                              </span>
                                              <br />
                                              <strong
                                                style={{
                                                  textTransform: "lowercase",
                                                }}
                                              >
                                                {inf.departure_time} (Local
                                                time)
                                              </strong>
                                            </div>

                                            <div
                                              style={{
                                                paddingLeft: "9px",
                                                paddingRight: "1rem",
                                                paddingTop: ".5rem",
                                                // borderRight: '1px solid #dee2e6',
                                                // borderColor: '#707070',
                                                flexBasis: "0",
                                                flexGrow: "1",
                                                minWidth: "0",
                                                maxWidth: "100%",
                                                textAlign: "left",
                                                lineHeight: "18px",
                                              }}
                                            >
                                              Arrival At : <br />
                                              <span
                                                style={{ fontSize: "12px" }}
                                              >
                                                {inf.arrival_date}
                                              </span>
                                              <br />
                                              <strong
                                                style={{
                                                  textTransform: "lowercase",
                                                }}
                                              >
                                                {inf.arrival_time} (Local time)
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            borderBottom: "1px solid #dee2e6",
                                            borderRight: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderColor: "#707070",
                                            flex: "0 0 25%",
                                            maxWidth: "25%",
                                            paddingTop: "4px",
                                            paddingLeft: "8px",
                                            lineHeight: "17px",
                                          }}
                                        >
                                          <div
                                            style={{ marginBottom: "0.5 rem" }}
                                          >
                                            Aircraft :<br />
                                            {inf.aircraft_type_name}
                                          </div>
                                          <div
                                            style={{ marginBottom: "0.5 rem" }}
                                          >
                                            Distance(Miles):
                                            <br />
                                            {inf.distance_miles}
                                          </div>
                                          <div
                                            style={{ marginBottom: "0.5rem" }}
                                          >
                                            Meals:{" "}
                                            {inf.meals ? inf.meals : "N/A"}
                                          </div>
                                        </div>
                                      </div>
                                      {/* {TicketInformation.baggage_info.map(bagInf)} */}
                                      <div
                                        style={{
                                          marginBottom: "13px",
                                          display: "flex",
                                          marginTop: "11px",
                                          marginRight: "-15px",
                                          marginLeft: "-15px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            flex: "0 0 100%",
                                            maxWidth: "100%",
                                            paddingRight: "15px",
                                            paddingLeft: "15px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              fontWeight: "400",
                                              fontSize: "12px",
                                              lineHeight: "15px",
                                            }}
                                          >
                                            Checked Baggage (Kg): Adult,
                                            {
                                              TicketInformation.baggage_info[0]
                                                .checked_weight_kg
                                            }
                                            ; Checked Baggage: Adult, Please see
                                            the airline rules
                                          </div>

                                          <div
                                            style={{
                                              fontWeight: "400",
                                              fontSize: "12px",
                                              lineHeight: "15px",
                                            }}
                                          >
                                            Cabin Baggage (Pcs): Adult,
                                            {
                                              TicketInformation.baggage_info[0]
                                                .cabin_bag_pcs
                                            }
                                            ; Checked Baggage: Adult, Please see
                                            the airline rules
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            flex: "0 0 50%",
                                            maxWidth: "33%",
                                            borderRight: "1px solid #dee2e6",
                                            borderColor: "#e7e7e9",
                                            marginTop: "3px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              marginBottom: ".25rem",
                                              backgroundColor: "#e7e7e9",
                                              fontSize: "12px",
                                            }}
                                          >
                                            Passenger name
                                          </div>
                                          {TicketInformation?.pxn_list &&
                                            TicketInformation.pxn_list.map(
                                              (inf) => (
                                                <div
                                                  style={{
                                                    fontWeight: "400",
                                                    fontSize: "11px",
                                                  }}
                                                >
                                                  <i className="fa fa-angle-double-right"></i>
                                                  <span
                                                    style={{
                                                      marginLeft: "5px",
                                                    }}
                                                  >
                                                    {inf.pxn_name}
                                                  </span>
                                                </div>
                                              )
                                            )}
                                        </div>
                                        <div
                                          style={{
                                            flex: "0 0 50%",
                                            maxWidth: "33%",
                                            marginTop: "3px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              paddingLeft: "15px",
                                              paddingBottom: "2px",
                                              marginBottom: "0.25rem",
                                              backgroundColor: "#e7e7e9",
                                              fontSize: "11px",
                                            }}
                                          >
                                            Seats:
                                          </div>
                                          {TicketInformation?.pxn_list &&
                                            TicketInformation.pxn_list.map(
                                              (_) => (
                                                <div
                                                  style={{
                                                    paddingLeft: "15px",
                                                    fontWeight: "400",
                                                    fontSize: "11px",
                                                  }}
                                                >
                                                  Check-in Required
                                                </div>
                                              )
                                            )}
                                        </div>
                                        <div
                                          style={{
                                            flex: "0 0 50%",
                                            maxWidth: "33%",
                                            marginTop: "3px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              paddingLeft: "15px",
                                              paddingBottom: "2px",
                                              marginBottom: "0.25rem",
                                              backgroundColor: "#e7e7e9",
                                              fontSize: "11px",
                                            }}
                                          >
                                            Ticket no:
                                          </div>
                                          {TicketInformation?.ticket_numbers ? (
                                            TicketInformation?.ticket_numbers.map(
                                              (inf) => (
                                                <>
                                                  {inf ? (
                                                    <div
                                                      style={{
                                                        fontWeight: "400",
                                                        fontSize: "11px",
                                                      }}
                                                    >
                                                      <i className="fa fa-angle-double-right"></i>
                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        {inf}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        paddingLeft: "15px",
                                                        fontWeight: "400",
                                                        fontSize: "11px",
                                                      }}
                                                    >
                                                      Check-in Required
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            )
                                          ) : (
                                            <div
                                              style={{
                                                paddingLeft: "15px",
                                                fontWeight: "400",
                                                fontSize: "11px",
                                              }}
                                            >
                                              Check-in Required
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TicketPage;
