import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import PnrTableSkeleton from "../../Skeleton/PnrTableSkeleton";

export default function PnrList() {
  const [pnr, setPnr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      fetch(`${process.env.REACT_APP_DEV_API_URL}/gds/get-user-pnr-list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPnr(data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      navigate("/");
    }
  }, []);

  const handleSendData = (slack) => {
    const status = "success";
    navigate(`/ticket?status=${status}&slack=${slack}`);
  };

  const dateFormatter = (datetime) => {
    // date and time
    const date = new Date(datetime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const dateString = `${year}-${month}-${day} , ${hours}:${minutes}:${seconds}`;
    return dateString;
  };

  const pnrList = pnr?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {isLoading ? (
        // <div
        //   className="d-flex justify-content-center align-items-center flex-column"
        //   style={{ height: "80vh" }}
        // >
        //   <loadedGif />
        //   <span>Loading...</span>
        // </div>
        <PnrTableSkeleton />
      ) : (
        <div
          className="table-div _scroll"
          style={{ width: "80vw", minHeight: "70vh" }}
        >
          <table class="table mt-4" style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">PNR</th>
                <th scope="col">Transaction Id.</th>
                <th scope="col">Amount</th>
                <th scope="col">Route</th>
                <th scope="col">Date & time </th>
                <th scope="col">Action</th>
                {/* <th scope='col'>View Invoice</th> */}
              </tr>
            </thead>
            <tbody>
              {pnrList?.length > 0 ? (
                pnrList.map((data, index) => (
                  <tr>
                    <th scope="row"> {index + 1}</th>
                    <td>{data.fabricated_pnr}</td>
                    <td>{data.transaction_number}</td>
                    <td>{data.total_amount}</td>

                    <td>
                      {data.travelers.map((dx, index) => (
                        <>
                          {/* <span>{dx}</span> */}
                          {index !== data.travelers.length - 1 ? (
                            <img
                              src="/assets/img/right-arrow.png"
                              style={{ height: "20px", width: "20px" }}
                              alt="hox"
                            />
                          ) : null}
                        </>
                      ))}
                    </td>
                    <td>{dateFormatter(data?.created_at)}</td>
                    <td>
                      {" "}
                      <p
                        className="btn btn-success"
                        style={{
                          backgroundColor: "#17c653",
                          border: "none",
                          boxShadow: "0px 0px 5px 0px #d8d8d8",
                        }}
                        onClick={() => handleSendData(data?.transaction_number)}
                      >
                        Download/view
                      </p>{" "}
                    </td>
                  </tr>
                ))
              ) : (
                <div style={{ minHeight: "60vh" }}>
                  <p style={{ textAlign: "center" }}>No Data has Found</p>
                </div>
              )}
            </tbody>
          </table>
        </div>
      )}
      {/* <div className="nodata">
        {pnr?.length === 0 ? (
          <div style={{ minHeight: "60vh" }}>
            <p style={{ textAlign: "center" }}>No Data has Found</p>
          </div>
        ) : null}
      </div> */}
    </div>
  );
}
