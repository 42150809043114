/* eslint-disable */
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

const Profile = () => {
  const cookies = new Cookies();

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Address, setAddress] = useState("");
  const [City, setCity] = useState("");
  const [pIssueCountry, setpIssueCountry] = useState("");
  const [pNationality, setpNationality] = useState("");
  const [pDocNo, setpDocNo] = useState("");
  const [pExpire, setpExpire] = useState("");

  const [DOB, setDOB] = useState("");
  const [Phone, setPhone] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [userProfilePic, setuserProfilePic] = useState("");
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  // const {
  //   data: userInfo,
  //   isLoading,
  //   error,
  // } = useQuery({
  //   queryKey: "user",
  //   queryFn: async () => {
  //     const response = await fetch(
  //       "https://subah.bdtask-demo.com/b2c/api/user/me",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     const data = await response.json();

  //     if (data.success == true) {
  //       localStorage.setItem("ml", data.data.email);

  //       setFirstName(data?.data?.first_name);
  //       setLastName(data?.data?.last_name);
  //       setPhone(data?.data?.phone);
  //       setPostalCode(data?.data?.postal_code);
  //       setAddress(data?.data?.address);
  //       setCity(data?.data?.city);
  //       setDOB(data?.data?.date_of_birth);
  //       setuserProfilePic(data?.data?.profile_pic);

  //       data?.data?.media.map((_r) => {
  //         if (_r.document_type === "Passport") {
  //           setpDocNo(_r.document_number);
  //           setpIssueCountry(_r.document_issuing_country);
  //           setpNationality(_r.document_nationality_country);
  //           setpExpire(_r.document_expiration_date);
  //         }
  //         if (_r.document_type === "Visa") {
  //         }
  //         if (_r.document_type === "NID") {
  //         }
  //       });
  //     } else {
  //       localStorage.removeItem("token");

  //       localStorage.removeItem("_user_id_");
  //       cookies.remove("auth_info");
  //       cookies.remove("_user_id_");
  //       cookies.remove("token");
  //       localStorage.removeItem("ml");

  //       throw new Error("User not found!");
  //     }

  //     return data;
  //   },
  // });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEV_API_URL}/user/me`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          localStorage.setItem("ml", data.data.email);

          setFirstName(data?.data?.first_name);
          setLastName(data?.data?.last_name);
          setPhone(data?.data?.phone);
          setPostalCode(data?.data?.postal_code);
          setAddress(data?.data?.address);
          setCity(data?.data?.city);
          setDOB(data?.data?.date_of_birth);
          setuserProfilePic(data?.data?.profile_pic);

          data?.data?.media.map((_r) => {
            if (_r.document_type === "Passport") {
              setpDocNo(_r.document_number);
              setpIssueCountry(_r.document_issuing_country);
              setpNationality(_r.document_nationality_country);
              setpExpire(_r.document_expiration_date);
            }
            if (_r.document_type === "Visa") {
            }
            if (_r.document_type === "NID") {
            }
          });
        } else {
          localStorage.removeItem("token");

          localStorage.removeItem("_user_id_");
          cookies.remove("auth_info");
          cookies.remove("_user_id_");
          cookies.remove("token");
          localStorage.removeItem("ml");

          throw new Error("User not found!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const submitHandler = (eve) => {
    eve.preventDefault();

    // const dataThatWillSend = JSON.stringify({
    //   user_id: localStorage.getItem("_user_id_slug") /** CHANGE TO SLUG **/,
    //   first_name: FirstName,
    //   last_name: LastName,
    //   phone: Phone,
    //   gender: "",
    //   address: Address,
    //   postal_code: PostalCode,
    //   city: City,
    //   country: "",
    //   date_of_birth: DOB,
    //   media: [
    //     {
    //       document_type: "Passport",
    //       document_number: pDocNo,
    //       document_issuing_country: pIssueCountry,
    //       document_nationality_country: pNationality,
    //       document_expiration_date: pExpire,
    //     },
    //     {
    //       document_type: "Visa",
    //       document_number: "",
    //       document_issuing_country: "",
    //       document_nationality_country: "",
    //       document_expiration_date: "",
    //     },
    //     {
    //       document_type: "NID",
    //       document_number: "",
    //       document_issuing_country: "",
    //       document_nationality_country: "",
    //       document_expiration_date: "",
    //     },
    //   ],
    // });

    fetch(`${process.env.REACT_APP_DEV_API_URL}/user/update-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: localStorage.getItem("_user_id"),
        first_name: FirstName,
        last_name: LastName,
        phone: Phone,
        gender: "",
        address: Address,
        postal_code: PostalCode,
        city: City,
        country: "",
        date_of_birth: DOB,
        media: [
          {
            document_type: "Passport",
            document_number: pDocNo,
            document_issuing_country: pIssueCountry,
            document_nationality_country: pNationality,
            document_expiration_date: pExpire,
          },
          {
            document_type: "Visa",
            document_number: "",
            document_issuing_country: "",
            document_nationality_country: "",
            document_expiration_date: "",
          },
          {
            document_type: "NID",
            document_number: "",
            document_issuing_country: "",
            document_nationality_country: "",
            document_expiration_date: "",
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success === true) {
          toast.success("Profile Has Been successfully updated", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error("Provider Error", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const userPicClick = async (e) => {
    document.getElementById("imgupload").click();
  };

  const uploadImage = async (e) => {
    try {
      var reader = new FileReader();
      reader.onloadend = function () {
        let img = reader.result.replace("data:image/jpeg;base64,", "");
        img = img.replace("data:image/png;base64,", "");
        img = img.replace("data:image/jpg;base64,", "");

        fetch(`${process.env.REACT_APP_DEV_API_URL}/user/profile-pic-update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_id: parseInt(localStorage.getItem("_user_id_")),
            img: img,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.success === true) {
              setuserProfilePic(reader.result);
              toast.success("Profile Pic Has Been successfully updated", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error(
                "Profile pic does not updated. Image size should be under 800KB! ",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };

      reader.readAsDataURL(e.target.files[0]);

      reader.onerror = function (error) {
        toast.error("Profile pic does not updated. Something Went Wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      };
    } catch (error) {
      toast.error("Something Went Wrong! Please try again.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="container py-5">
        <div className="row">
          <div className="col-md-3">
            <div className="card shadow border-0 mb-3">
              {token ? (
                <div className="card-body">
                  <h3 className="fs-17 mb-0">Settings</h3>
                  <hr />
                  <ul className="nav flex-column">
                    {/* <li className='nav-item'>
                    <a
                      className='nav-link text-dark d-flex align-items-center'
                      data-scroll=''
                      href='#general-terms'
                    >
                      <div className='me-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='22.08'
                          height='22.08'
                          viewBox='0 0 22.08 22.08'
                        >
                          <path
                            d='M8.884,22.08H5.175A5.181,5.181,0,0,1,0,16.905V5.175A5.181,5.181,0,0,1,5.175,0h11.73A5.181,5.181,0,0,1,22.08,5.175V9.488a.863.863,0,1,1-1.725,0V5.175a3.454,3.454,0,0,0-3.45-3.45H5.175a3.454,3.454,0,0,0-3.45,3.45v11.73a3.454,3.454,0,0,0,3.45,3.45H8.884a.863.863,0,1,1,0,1.725ZM10.048,6.038A2.588,2.588,0,1,0,7.461,8.625,2.59,2.59,0,0,0,10.048,6.038Zm-1.725,0a.863.863,0,1,1-.863-.863A.863.863,0,0,1,8.323,6.038ZM18.63,8.366a.862.862,0,0,0-.863-.863H12.42a.863.863,0,0,0,0,1.725h5.348A.862.862,0,0,0,18.63,8.366ZM12.248,14.4a.862.862,0,0,0-.863-.863h-6.3a.863.863,0,0,0,0,1.725h6.3A.862.862,0,0,0,12.248,14.4ZM8.8,17.423a.862.862,0,0,0-.863-.863H5.089a.863.863,0,1,0,0,1.725H7.935A.862.862,0,0,0,8.8,17.423ZM12.5,22.063l2.444-.488a6.218,6.218,0,0,0,3.188-1.706l3.023-3.023a3.148,3.148,0,1,0-4.452-4.452l-3.023,3.023A6.218,6.218,0,0,0,11.976,18.6l-.488,2.444A.862.862,0,0,0,12.5,22.063Zm7.435-8.45a1.423,1.423,0,0,1,0,2.013l-3.023,3.023a4.5,4.5,0,0,1-2.306,1.234l-1.175.235.235-1.175A4.5,4.5,0,0,1,14.9,16.637l3.023-3.024a1.423,1.423,0,0,1,2.013,0Zm-2.171-7.4H12.42a.863.863,0,0,1,0-1.725h5.348a.863.863,0,0,1,0,1.725Zm-7.383,5.583a.862.862,0,0,0-.057-1.218,3.842,3.842,0,0,0-2.592-1H7.187a3.842,3.842,0,0,0-2.592,1A.863.863,0,0,0,5.756,11.85,2.121,2.121,0,0,1,7.187,11.3h.548a2.121,2.121,0,0,1,1.431.552.863.863,0,0,0,1.218-.057Z'
                            fill='#2b2b2b'
                          />
                        </svg>
                      </div>
                      Edit Profile
                    </a>
                  </li> */}

                    <li className="nav-item">
                      <Link
                        className="nav-link text-dark d-flex align-items-center"
                        data-scroll=""
                        to="/changepwd"
                      >
                        <div className="me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18.036"
                            height="20.613"
                            viewBox="0 0 18.036 20.613"
                          >
                            <circle
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              transform="translate(12 12.47)"
                              fill="#2b2b2b"
                            />
                            <circle
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              transform="translate(8 12.47)"
                              fill="#2b2b2b"
                            />
                            <path
                              d="M49.231,14.574a.805.805,0,0,0,.805-.805V10.789a3.224,3.224,0,0,0-3.221-3.221h-.968V4.729a4.832,4.832,0,0,0-9.662,0v2.84h-.965A3.224,3.224,0,0,0,32,10.789v6.6a3.224,3.224,0,0,0,3.221,3.221H46.815a3.224,3.224,0,0,0,3.221-3.221.805.805,0,0,0-1.61,0A1.612,1.612,0,0,1,46.815,19H35.221a1.612,1.612,0,0,1-1.61-1.61v-6.6a1.612,1.612,0,0,1,1.61-1.61H46.815a1.612,1.612,0,0,1,1.61,1.61v2.979A.805.805,0,0,0,49.231,14.574ZM44.237,7.569H37.8V4.729a3.222,3.222,0,0,1,6.441,0Z"
                              transform="translate(-32)"
                              fill="#2b2b2b"
                            />
                            <circle
                              cx="1.5"
                              cy="1.5"
                              r="1.5"
                              transform="translate(3 12.47)"
                              fill="#2b2b2b"
                            />
                          </svg>
                        </div>
                        Change Password
                      </Link>
                    </li>
                    {/* <li className='nav-item'>
                    <a
                      className='nav-link text-dark d-flex align-items-center'
                      data-scroll=''
                      href='#license'
                    >
                      <div className='me-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='21.778'
                          height='16.541'
                          viewBox='0 0 21.778 16.541'
                        >
                          <path
                            d='M0,61.708V72.733a2.764,2.764,0,0,0,2.758,2.758h8.91a2.764,2.764,0,0,0,2.758-2.758V70.947a.546.546,0,0,0-1.093,0v1.786a1.674,1.674,0,0,1-1.67,1.67H2.758a1.674,1.674,0,0,1-1.67-1.67V61.708a1.674,1.674,0,0,1,1.67-1.67h8.91a1.674,1.674,0,0,1,1.67,1.67v1.786a.546.546,0,1,0,1.093,0V61.708a2.764,2.764,0,0,0-2.758-2.758H2.758A2.761,2.761,0,0,0,0,61.708Z'
                            transform='translate(0 -58.95)'
                          />
                          <path
                            d='M214.255,157.332a.547.547,0,0,0,.773,0l3.727-3.727a.542.542,0,0,0,0-.768l-3.727-3.727a.543.543,0,0,0-.768.768l2.8,2.8H206.846a.546.546,0,1,0,0,1.093h10.207l-2.8,2.8A.536.536,0,0,0,214.255,157.332Z'
                            transform='translate(-197.137 -144.952)'
                          />
                        </svg>
                      </div>
                      Log Out
                    </a>
                  </li> */}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-9">
            <div className="card shadow border-0 mb-3 profile-card">
              <div className="card-body ps-md-5 pe-md-5 row">
                <div className="col-md-9">
                  <Link
                    className="btn btn-primary m-2 border-0"
                    to="/"
                    style={{ background: "#17c653", padding: "0.375rem 50px" }}
                  >
                    Go back
                  </Link>
                </div>
                <div className="col-md-3">
                  {/* <div className="row">
                    <div className="small-12 medium-2 large-2 columns">
                      <div className="circle">
                        <img className="profile-pic" src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" />

                      </div>
                      <div className="p-image">
                        <i className="fa fa-camera upload-button"></i>
                          <input className="file-upload" type="file" accept="image/*"/>
                      </div>
                    </div>
                  </div> */}

                  <input
                    type="file"
                    id="imgupload"
                    style={{ display: "none" }}
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => uploadImage(e)}
                  />
                  <div
                    style={{
                      height: "175px",
                      width: "98%",
                      marginTop: "2px",
                      borderRadius: "50%",
                    }}
                    onClick={(e) => userPicClick(e)}
                  >
                    <img
                      src={userProfilePic ? userProfilePic : "/upload.jpg"}
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      className="user_profile_pic"
                      title="Click to upload image."
                    />
                    <i
                      className="fa fa-edit"
                      title="Click to upload image."
                    ></i>
                  </div>
                </div>
              </div>
              <div className="card-body p-md-5">
                <form onSubmit={submitHandler}>
                  <div className="content-header d-flex mb-3">
                    <div
                      className="
                    card-icon
                    d-flex
                    align-items-center
                    justify-content-center
                    rounded
                    text-white
                    me-3
                  "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-shield"
                      >
                        <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                      </svg>
                    </div>
                    <div className="media-body">
                      <h3 className="content-header_title fs-23 mb-0">
                        Profile
                      </h3>
                      <p>Basic information for a faster booking experience</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="fs-14 font-weight-500">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={FirstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="fs-14 font-weight-500">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={LastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h4 className="mb-4">Personal Information</h4>
                  <div className="row">
                    <div className="col-md-12">
                      <h5 className="mb-2">Present Address</h5>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="House Number"
                          value={Address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Postal Code"
                          value={PostalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="City"
                          value={City}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="fs-14 font-weight-500">
                          Date Of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={DOB}
                          onChange={(e) => setDOB(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="fs-14 font-weight-500">
                          Passport Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={pDocNo}
                          onChange={(e) => setpDocNo(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="fs-14 font-weight-500">
                          Passport Issuing Country
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={pIssueCountry}
                          onChange={(e) => setpIssueCountry(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="fs-14 font-weight-500">
                          Passport Expiry Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={pExpire}
                          onChange={(e) => setpExpire(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="fs-14 font-weight-500">
                          Emergency Contact Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={Phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-primary border-0"
                      style={{ textAlign: "right", background: "#17c653" }}
                      type="submit"
                      disabled
                    >
                      Update Profile
                    </button>
                  </div>
                  <hr />
                  <div className="text-center">
                    *Travel Protection is only valid for Bangaladeshi Citizens
                    below 70 years of age.{" "}
                    <Link to="/term-condition">Terms & Condition</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
