import React, { useEffect, useState } from "react";
import "./flight.css";

import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import FlightCardComp from "./FlightCardComp";

export default function FlightCard({
  flight,
  setFlight,
  filteredAirlines,
  legDescription,
}) {
  const [isDetailed, setIsDetailed] = useState(false);
  const [tabKey, setTabKey] = useState("flight_info");
  const [tabIndex, setTabIndex] = useState();
  const navigate = useNavigate();

  const handleDetails = (index) => {
    setTabIndex(index);
    setIsDetailed(!isDetailed);
    if (isDetailed) {
      setTabIndex(null);
    }
  };

  const timeCalc = (time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    if (hours === 0) return minutes + "min";
    return hours + "hr " + minutes + "min";
  };

  const removeCountryCode = (time) => {
    // make it just time not country code
    return time.replace(/.*?(\d{2}:\d{2}).*/, "$1");
  };

  const priceFormat = (price) => {
    // make the thousand with ","
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  // const airportNameFind = (shortCode) => {
  //   const airport = options.find((airport) => airport.value === shortCode);
  //   return airport?.label;
  // };\

  // let customFlightInfo = [];
  // customFlightFilter?.map((item) => {
  //   customFlightInfo?.push(
  //     filteredAirlines.map((fly) => {
  //       fly.schedules.find((schedule) => {
  //         if (schedule?.departure_airport === item) {
  //           return schedule;
  //         }
  //       });
  //     })
  //   );
  // });

  const [chargeDetails, setChargeDetails] = useState({
    admChargeType: "",
    admChargeAmount: "",
    sslChargeAmount: "",
    sslChargeType: "",
    commission_percentage: "",
    commission_Type: "",
    updated: false,
  });
  const [currency, setCurrency] = useState("BDT");

  const {
    data: settings,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["settings"],
    queryFn: () =>
      fetch(`${process.env.REACT_APP_DEV_API_URL}/application-settings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const _d = data.data;
          setChargeDetails({
            admChargeType: _d.admin_charge_type,
            admChargeAmount: _d.admin_charge,
            sslChargeAmount: _d.payment_gateway_charge,
            sslChargeType: _d.payment_gateway_charge_type,
            commission_percentage: _d.commission_percentage,
            commission_Type: _d.commission_type,
            updated: true,
          });
          setCurrency(_d.currency);
        }),
  });

  return (
    <div className="mb-5">
      {filteredAirlines?.length > 0 ? (
        <>
          <div lg={12} className="flight_card_container">
            {filteredAirlines?.map((fly, index) => (
              <FlightCardComp
                fly={fly}
                index={index}
                chargeDetails={chargeDetails}
                setChargeDetails={setChargeDetails}
                legDescription={legDescription}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div
            className="no-result-found"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "60vh",
              flexDirection: "column",
              padding: 0,
              margin: 0,
            }}
          >
            <p>Sorry. No Flight is Available for this filter</p>
            <p>Thank You</p>
          </div>
        </>
      )}
    </div>
  );
}
