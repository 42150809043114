/* eslint-disable */
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgetPassword = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["_forget_token"]);
  const [mail, setMail] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const navigate = useNavigate();

  const baseurl = process.env.REACT_APP_DEV_BASE_URL;

  useEffect(() => {
    removeCookie("_forget_token");
  }, []);

  const formSubmitHandler = (eve) => {
    eve.preventDefault();
    setIsDisable(true);
    const body = {
      email: mail,
      site_url: process.env.REACT_APP_DEV_BASE_URL + "/resetuserpassword",
    };
    fetch(`${process.env.REACT_APP_DEV_API_URL}/user/password/forgot`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status) {
          toast.success(response?.status, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          localStorage.setItem("ml", mail);

          // setCookie("_forget_token", response.body.token, {
          //   path: "/",
          // });

          // setTimeout(() => {
          //   navigate("/resetuserpassword");
          // }, 3000);
        } else {
          setIsDisable(false);
          toast.error(response.errors.email[0], {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div class="min-vh-100 py-5">
        <div class="container">
          <div class="row justify-content-center mb-md-6">
            <div class="col-auto">
              <a href="/">
                <img
                  src="assets/img/logo-desktop.png"
                  alt="Leap"
                  style={{ maxHeight: "110px" }}
                />
              </a>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-5 col-md-6">
              <div class="text-center mb-4">
                <h1 class="h2 mb-1">Forgot Password</h1>
                <span>
                  Enter your registered mail address to recieve a secret token
                </span>
              </div>
              <form onSubmit={formSubmitHandler}>
                <div class="form-group">
                  <input
                    type="email"
                    name="forgot-password-email"
                    placeholder="Email Address"
                    class="form-control"
                    value={mail}
                    onChange={(eve) => setMail(eve.target.value)}
                    required
                  />
                </div>
                <div class="form-group mt-2">
                  <button
                    class="btn-block btn btn-primary"
                    type="submit"
                    disabled={isDisable}
                  >
                    Send Reset Token
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
