/* eslint-disable */
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader.gif";
import "./ManageBookingStyle.css";
import Cookies from "universal-cookie";
import formatter from "currency-formatter";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BookingInfoSkeleton from "../Skeleton/BookingInfoSkeleton";

const ManageBooking = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [genToken, setGenToken] = useState(false);
  const [rs, setRs] = useState();

  const [BookingData, setBookingData] = useState({});
  const [ErrorOcr, setErrorOcr] = useState(true);
  const [Loading, setLoading] = useState(true);
  const [is_cancelled, setis_cancelled] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const refData = queryParams.get("refData");

  useEffect(() => {
    const url = `${process.env.REACT_APP_CERT_GET_APP_SETTING_API}`;
    const api_key = `${process.env.REACT_APP_CERT_GET_APP_SETTING_X_API}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": api_key,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        const mode = localStorage.getItem("isprd");
        if (res.body.responseData.IsProduction + "" !== mode) {
          localStorage.setItem("isprd", res.body.responseData.IsProduction);
          localStorage.setItem("ctn", "make");
          setGenToken(true);
        } else {
          setGenToken(false);
        }
      });
  }, []);

  useEffect(() => {
    if (
      cookies.get("token") === undefined ||
      Date.now() < cookies.get("token").expires ||
      localStorage.getItem("ctn") === "make"
    ) {
      localStorage.removeItem("ctn");
      fetch(`${process.env.REACT_APP_CERT_GEN_TOKEN_API}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${process.env.REACT_APP_CERT_GEN_TOKEN_X_API}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.statusCode !== 400) {
            setRs("x");
            cookies.set("token", result.body, { path: "/" });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [genToken]);

  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_DEV_API_URL}/gds/get-booking-info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pnr_no: refData,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === true) {
          setBookingData(result.data);
          setis_cancelled(result.is_cancelled);
          setLoading(false);
          setErrorOcr(false);
        } else {
          setLoading(false);
          setErrorOcr(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [refData]);

  const cancelTicket = () => {
    fetch(`${process.env.REACT_APP_HOX_DEL_TICKET_CHECK_API}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": `${process.env.REACT_APP_HOX_DEL_TICKET_CHECK_X_API}`,
      },
      body: JSON.stringify({
        pnr_no: BookingData?.skytrip_pnr,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.body.request_state === "not-requested") {
          fetch(`${process.env.REACT_APP_HOX_DEL_TICKET_API}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": `${process.env.REACT_APP_HOX_DEL_TICKET_X_API}`,
            },
            body: JSON.stringify({
              pnr_no: BookingData?.skytrip_pnr,
            }),
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.statusCode === 200) {
                toast.success("Requested Successfully", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          toast.error("Already in progress", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const refactorTime = (time) => {
    if (time <= 60) {
      return time + " Minutes";
    } else {
      let newTime = Math.floor(time / 60);
      return `${newTime} Hours  ${time - newTime * 60} Minutes`;
    }
  };


  return (
    <div className="container" style={{ minHeight: "60vh" }}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      {Loading ? (
        // <div
        //   className="d-flex justify-content-center align-items-center flex-column"
        //   style={{ minHeight: "60vh" }}
        // >
        //   <img src={Loader} alt="Loader" />
        //   <span className="mt-4 fs-1">Getting Data ...</span>
        // </div>
        <BookingInfoSkeleton />
      ) : ErrorOcr || is_cancelled ? (
        <>
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "60vh",
              flexDirection: "column",
            }}
          >
            <p className="fs-3">
              Sorry, No Data Found.{" "}
              {is_cancelled
                ? "Booking is cancelled"
                : "Please Search with correct Infortamtion."}
            </p>{" "}
            <Link to="/" className="btn btn-warning">
              Search Again
            </Link>
          </div>
        </>
      ) : (
        <>
          <div className="container ">
            <div
              className="__booking_ref mt-4"
              style={{
                fontSize: "15px",
                textTransform: "uppercase",
                letterSpacing: ".8px",
                wordSpacing: "1px",
              }}
            >
              <div className="row">
                <div className="col p-3 border me-2">
                  <span>
                    Booking Reference:{" "}
                    <span style={{ fontWeight: "600" }}>
                      {BookingData?.pnr}
                    </span>{" "}
                  </span>
                </div>
                <div className="col p-3 border ms-2">
                  Status:{" "}
                  {BookingData?.is_cancelled ? (
                    <span style={{ color: "red" }}>Cancelled</span>
                  ) : (
                    <span style={{ color: "#17c653" }}>Confirmed</span>
                  )}
                </div>
              </div>

              <div className="__top_container row mt-4 mb-4 ">
                <div className="col me-1">
                  <div className="row" style={{ border: "1px solid #d3d3d3" }}>
                    <div
                      className="col"
                      style={{
                        borderRight: "1px solid #d3d3d3",
                        padding: "14px",
                      }}
                    >
                      <span>Journey Starts</span>
                      <br />
                      <span>
                        {BookingData?.journey_startDate
                          ? BookingData?.journey_startDate
                          : ""}
                      </span>
                    </div>
                    <div className="col" style={{ padding: "14px" }}>
                      <span>Journey Ends</span>
                      <br />
                      <span className="mt-1">
                        {BookingData?.journey_endDate
                          ? BookingData?.journey_endDate
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="col ms-1"
                  style={{ border: "1px solid #d3d3d3" }}
                >
                  <div className="row">
                    <div
                      className="col"
                      style={{
                        borderRight: "1px solid #d3d3d3",
                        padding: "14px",
                      }}
                    >
                      <span>Ticket Status: </span>

                      {BookingData?.is_cancelled ? (
                        <span style={{ color: "red" }}>Cancelled</span>
                      ) : (
                        <span style={{ color: "#17c653" }}>Confirmed</span>
                      )}
                    </div>
                    <div
                      className="col"
                      style={{
                        padding: "14px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Is Cancelable :{" "}
                      <p
                        className="m-0 ms-2"
                        style={{ userSelect: "none", pointerEvents: "none" }}
                      >
                        {BookingData?.is_cancelable}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container __flight__descriptions card shadow border-0 mb-3">
                <div className="contenet card-body">
                  <div className="flight-info border rounded">
                    {BookingData?.flights?.map((stop, index) => {
                      return (
                        <div key={index}>
                          <Row className="row review-article my-3 align-items-center no-gutters">
                            <Col
                              sm={3}
                              lg={2}
                              className="text-sm-center mb-4 mb-sm-0 __image-name"
                            >
                              <div className="brand-img">
                                <img
                                  src={
                                    "https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/" +
                                    stop?.airline_code +
                                    ".png"
                                  }
                                  alt=""
                                  className="img-fluid _img"
                                />{" "}
                              </div>
                              <div className="airline-box">
                                <div className="fs-13 fs-13-1">
                                  {stop?.airline_code} - {stop?.flight_number}
                                  <br />
                                  {stop?.aircraft_type_name}
                                </div>
                                <div className="fs-13 text-muted"> </div>
                              </div>
                            </Col>
                            <Col sm={9} lg={10}>
                              <Row>
                                <Col
                                  md={3}
                                  sm={12}
                                  className="_vax text-center"
                                >
                                  <div className="fs-13 font-weight-500">
                                    {stop?.from_airport}
                                  </div>
                                  <h3 className="fs-21 font-weight-600">
                                    {stop?.departure_time}
                                  </h3>

                                  <span className="text-muted fs-13">
                                    {stop?.from_airport}
                                  </span>
                                </Col>

                                <Col
                                  md={6}
                                  className="text-center fs-14 mt-4 mt-md-0"
                                >
                                  <div
                                    className="d-sm-flex align-items-center justify-content-center"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <span className="d-inline-flex align-items-center">
                                      <img
                                        src="/assets/img/clock.svg"
                                        alt="clock"
                                        className="img-fluid service-icon me-2"
                                      />
                                      {refactorTime(stop?.duration_minutes)}
                                    </span>
                                    <span className="d-inline-flex align-items-center justify-content-center">
                                      &nbsp; &nbsp;
                                      <span className="text-muted">|</span>
                                      &nbsp; &nbsp;
                                      <img
                                        src="/assets/img/meal-1.svg"
                                        alt=""
                                        className="img-fluid service-icon me-2"
                                      />
                                      <span>
                                        Meal type-{" "}
                                        {stop?.meals == ""
                                          ? "N/A"
                                          : stop?.meals}
                                      </span>
                                    </span>
                                    <span className="d-inline-flex align-items-center">
                                      &nbsp; &nbsp;
                                      <span className="text-muted">|</span>
                                      &nbsp; &nbsp;
                                      <img
                                        src="/assets/img/economy.svg"
                                        alt=""
                                        className="img-fluid service-icon me-2"
                                      />
                                      {stop?.cabin_class}
                                    </span>
                                  </div>
                                  <div className="two-dots my-3 text-muted position-relative border-top">
                                    <span className="flight-service">
                                      <img
                                        src="/assets/img/airplane.svg"
                                        alt=""
                                        className="img-fluid service-icon service-icon_flight mr-1"
                                      />
                                      <span className="type-text px-2 position-relative">
                                        Flight
                                      </span>
                                    </span>
                                  </div>

                                  <div className="d-sm-flex align-items-center justify-content-center">
                                    <span>
                                      Distance - {stop?.distance_miles} Miles
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  md={3}
                                  sm={12}
                                  className="_vax text-center"
                                >
                                  <div className="fs-13 font-weight-500">
                                    {stop?.to_airport}
                                  </div>
                                  <h3 className="fs-21 font-weight-600">
                                    {stop?.arrival_time}
                                  </h3>

                                  <span className="text-muted fs-13">
                                    {stop?.to_airport}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          {index === BookingData?.flights.length - 1 ? null : (
                            <div className="separator position-relative d-flex align-items-center justify-content-between my-3">
                              <span className="tri-right"></span>
                              <span className="tri-left"></span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-9">
                  <div className="__passenger_informations">
                    <button
                      class="btn w-100"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#passengerInformation"
                      aria-expanded="false"
                      aria-controls="passengerInformation"
                      style={{
                        backgroundColor: "#17c653",
                        color: "white",
                        height: "50px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>
                        Passenger's Information
                      </span>
                      <i className="fas fa-chevron-circle-down ms-1"></i>
                    </button>

                    <div class="collapse mt-2" id="passengerInformation">
                      <table className="table text-center table-bordered">
                        <thead className="table__header">
                          <tr>
                            <th>Name</th>
                            <th>Passenger Type</th>
                            <th>Ticket status</th>
                            {/* <th>Ticket Fare</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {BookingData?.travellers?.map((traveler) => (
                            <>
                              <tr>
                                <td>{traveler?.passenger_givenName}</td>
                                <td>{traveler?.passenger_type}</td>
                                <td>
                                  {traveler?.ticket_number
                                    ? traveler?.ticket_number
                                    : "In Process"}
                                </td>
                                {/* <td>
                                  {traveler.total
                                    ? formatter.format(traveler?.total, {
                                        code: "BDT",
                                      })
                                    : "Check Fare Summary"}
                                  {traveler?.total && " Taka"}
                                </td> */}
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* <div className='__baggageInformation mt-4'>
                    <button
                      class='btn w-100'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#baggageInformation'
                      aria-expanded='false'
                      aria-controls='baggageInformation'
                      style={{
                        backgroundColor: '#001787',
                        color: 'white',
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ fontWeight: '600' }}>
                        Baggage Information
                      </span>
                      <i className='fas fa-chevron-circle-down ms-1'></i>
                    </button>
                    <div class='collapse mt-2' id='baggageInformation'>
                      <div className='card card-body'>
                        {BookingData?.baggage?.length > 0 ? (
                          <>
                            <p
                              className='p-2 mb-0'
                              style={{ fontWeight: '600', fontSize: '15px' }}
                            >
                              Check-In Baggage
                            </p>

                            <div className='border p-4'>
                              <span
                                style={{ fontSize: '15px', fontWeight: '500' }}
                              >
                                Total Weight You can carry -
                                {
                                  BookingData?.baggage[0]?.checkedBaggageAllowance
                                    ?.totalWeightInKilograms
                                }{' '}
                                KG
                              </span>
                            </div>

                            <p
                              className='p-2 mb-0 mt-3'
                              style={{ fontWeight: '600', fontSize: '15px' }}
                            >
                              Cabin Baggage Allowance
                            </p>

                            <table className='table text-center table-bordered'>
                              <thead className='table__header'>
                                <tr>
                                  <th>Maximum Bag Size (in Inch)</th>
                                  <th>Maximum Bag Size (in cm)</th>
                                  <th>Maximum Bag Weight (in Pound)</th>
                                  <th>Maximum Bag Weight (in KG)</th>
                                  <th>Maximum Bag (Piece)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      BookingData?.baggage[0] 
                                        ?.cabinBaggageAllowance[0]
                                        ?.maximumSizeInInches
                                    }{' '}
                                    Inc
                                  </td>
                                  <td>
                                    {
                                      BookingData?.baggage[0]
                                        ?.cabinBaggageAllowance[0]
                                        ?.maximumSizeInCentimeters
                                    }{' '}
                                    Cm
                                  </td>
                                  <td>
                                    {
                                      BookingData?.baggage[0]
                                        ?.cabinBaggageAllowance[0]
                                        ?.maximumWeightInPounds
                                    }{' '}
                                    Lb
                                  </td>
                                  <td>
                                    {
                                      BookingData?.baggage[0]
                                        ?.cabinBaggageAllowance[0]
                                        ?.maximumWeightInKilograms
                                    }{' '}
                                    Kg
                                  </td>
                                  <td>
                                    {
                                      BookingData?.baggage[0]
                                        ?.cabinBaggageAllowance[0]
                                        ?.numberOfPieces
                                    }{' '}
                                    Piece
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        ) : (
                          <span>No Data Available</span>
                        )}
                      </div>
                    </div>
                  </div> */}

                  <div className="refund__policy mt-4">
                    <button
                      class="btn w-100"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#fareRules"
                      aria-expanded="false"
                      aria-controls="fareRules"
                      style={{
                        backgroundColor: "#17c653",
                        color: "white",
                        height: "50px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Fare Rules</span>
                      <i className="fas fa-chevron-circle-down ms-1"></i>
                    </button>

                    {BookingData?.fareRules?.length > 0 ? (
                      <>
                        <div class="collapse mt-2" id="fareRules">
                          <div className="card p-3">
                            <p
                              className="p-3 w-25 text-center mb-0"
                              style={{
                                backgroundColor: "#001787",
                                color: "white",
                              }}
                            >
                              Refund Policy
                            </p>
                            <div className="card-body border">
                              <table className="table text-center table-bordered">
                                <thead className="table__header">
                                  <tr>
                                    <th>Applicabilty</th>
                                    <th>Conditions</th>
                                    <th>Penalty Ammount / Percent</th>
                                    <th>Penalty Currency</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {BookingData?.fareRules
                                        ? BookingData?.fareRules[0]?.refund[0]
                                            ?.applicability
                                        : ""}
                                    </td>
                                    <td>
                                      {BookingData?.fareRules[0].refund[0]
                                        ?.isCondition
                                        ? "True"
                                        : "False"}
                                    </td>
                                    <td>
                                      {BookingData?.fareRules[0]?.refund[0]
                                        ?.type === "percentage"
                                        ? BookingData?.fareRules[0]?.refund[0]
                                            ?.penalty_amount + "%"
                                        : formatter.format(
                                            BookingData?.fareRules[0]?.refund[0]
                                              ?.penalty_amount,
                                            {
                                              code: "BDT",
                                            }
                                          )}
                                    </td>
                                    <td>
                                      {
                                        BookingData?.fareRules[0]?.refund[0]
                                          ?.penalty_currencyCode
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="card p-3 mt-2 ">
                            <p
                              className="p-3 w-25 text-center mb-0"
                              style={{
                                backgroundColor: "#001787",
                                color: "white",
                              }}
                            >
                              Exchange Policy
                            </p>
                            <div className="card-body border">
                              <table className="table text-center table-bordered">
                                <thead className="table__header">
                                  <tr>
                                    <th>Applicabilty</th>
                                    <th>Conditions</th>
                                    <th>Penalty Ammount / Percent</th>
                                    <th>Penalty Currency</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      {
                                        BookingData?.fareRules[0]?.exchange[0]
                                          ?.applicability
                                      }
                                    </td>
                                    <td>
                                      {BookingData?.fareRules[0]?.exchange[0]
                                        ?.isCondition
                                        ? "True"
                                        : "False"}
                                    </td>
                                    <td>
                                      {BookingData?.fareRules[0]?.exchange[0]
                                        ?.type === "percentage"
                                        ? BookingData?.fareRules[0]?.exchange[0]
                                            ?.exchange_penalty_amount + "%"
                                        : formatter.format(
                                            BookingData?.fareRules[0]
                                              ?.exchange[0]
                                              ?.exchange_penalty_amount,
                                            {
                                              code: "BDT",
                                            }
                                          )}
                                    </td>
                                    <td>
                                      {
                                        BookingData?.fareRules[0]?.exchange[0]
                                          ?.exchange_penalty_currencyCode
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div class="collapse mt-2" id="fareRules">
                        <div className="card">
                          <div className="card-body">
                            <span>No Data Available</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className='col-3'>
                  <div className='card'>
                    <div
                      className='card-header shadow'
                      style={{ fontSize: '16px', fontWeight: '600' }}
                    >
                      Fare Summary
                    </div>
                    <div className='card-body'>
                      <div
                        className='d-flex justify-content-between'
                        style={{ fontSize: '17px' }}
                      >
                        <span>Sub Total</span>
                        <span>
                          {formatter.format(
                            BookingData?.payments?.flightTotals[0]?.subtotal,
                            {
                              code: BookingData?.payments?.flightTotals[0]
                                ?.currencyCode,
                            }
                          )}
                        </span>
                      </div>

                      <div
                        className='d-flex justify-content-between'
                        style={{ fontSize: '17px' }}
                      >
                        <span>Taxs</span>
                        <span>
                          {formatter.format(
                            BookingData?.payments?.flightTotals[0]?.taxes,
                            {
                              code: BookingData?.payments?.flightTotals[0]
                                ?.currencyCode,
                            }
                          )}
                        </span>
                      </div>

                      <hr className='mt-2 mb-1' />

                      <div
                        className='d-flex justify-content-between'
                        style={{ fontSize: '17px' }}
                      >
                        <span>Total</span>
                        <span>
                          {formatter.format(
                            BookingData?.payments?.flightTotals[0]?.total,
                            {
                              code: BookingData?.payments?.flightTotals[0]
                                ?.currencyCode,
                            }
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div
              className="footer___support"
              style={{ minHeight: "20px" }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageBooking;
