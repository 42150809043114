import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Visa/Loader.gif";
import "./style.css";

export default function PrivacyPolicy() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEV_API_URL}/application-settings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success == true) {
          setLoading(false);
          setData(data.data.privacy_policy);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  }, []);

  return (
    <div className="container" id="#container">
      <div
        className="row"
        style={{
          margin: "auto",
          width: "100%",
          padding: "80px 0",
          minHeight: "850px",
        }}
      >
        {data == null || data?.undefined ? (
          <p className="text-center">No data found</p>
        ) : (
          <>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "80vh",
                }}
              >
                <img src={Loader} alt="loader" />
              </div>
            ) : (
              <div
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: data }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
